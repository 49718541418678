import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/__mocks__';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import React from 'react';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import { initialize as initializeServiceWorker, update as updateServiceWorker } from 'src/slices/serviceWorker';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Provider store={store}>
  <SettingsProvider>
    <App />
  </SettingsProvider>
</Provider>);

enableES5();
// serviceWorker.register();
if (process.env.REACT_APP_TYPE === 'BROWSER') {
  serviceWorker.register({
    onSuccess: () => {
      store.dispatch(initializeServiceWorker({ initialized: true }));
    },
    onUpdate: reg => {
      store.dispatch(updateServiceWorker({ updated: true, registration: reg }));
    },
  });
}
