import { ItemStatus } from 'src/types/common';
import { Pos } from 'src/types/pos';
import { StationType } from 'src/types/station';
import { api } from 'src/utils/api';
export const appType = 'Checkout';
export const barcodeMinLength = 4;
export const taxAmountForDemoMode = 8;

export enum LogLevel {
  // eslint-disable-next-line no-unused-vars
  Trace = 'Trace',
}

export type LogToCloudType = {
  message: string,
  logLevel?: LogLevel,
  Source?: string,
  data?: any;
};

export const groupItems = (items) => {
  const getItems = items.reduce((result, item) => {
    if (item.product && item.itemStatus !== ItemStatus.Voided) {
      result[item.productId] = [...result[item.productId] || [], { ...item, isUnknown: false }];
    } else {
      //Add a fake item for this unknown product
      if (item.itemStatus !== ItemStatus.Voided) {
        const unknownItem = Object.assign({}, item);
        unknownItem.product = {
          name: 'Unknown',
          type: 'Unknown',
          upc: 'Unknown',
          sku: 'Unknown',
          isUnknown: true,
        };
        result['Unknown'] = [...result[unknownItem.product.upc] || [], unknownItem];
      }
    }
    return result;
  }, {});
  const finalResult = [];
  Object.keys(getItems).forEach((item) => {
    if (getItems[item] && getItems[item][0] && getItems[item][0].product) {
      finalResult.push({
        product: {
          ...getItems[item][0].product,
          quantity: getItems[item].length,
          epcPrefixes: getItems[item]?.map(i => i.product.epcPrefix)
        }
      });
    }
  });

  return finalResult;
};
export const randomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
};
export function removeHTML(str) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || '';
}
export function RedirectAsPerOrderStatus(status, itemLength, isDemo = false) {
  switch (status) {
    case 'None': {
      return '/app/attract';
    }
    case 'Open': {
      if (itemLength === 0) {
        return '/app/attract';
      } else {
        return '/app/item-details-for-payment/';
      }
    }
    case 'Review': {
      return '/app/item-details-for-payment/checkout';
    }
    case 'Payment': {
      if (isDemo) {
        return '/app/item-details-for-payment/complete-order';
      } else {
        return '/app/item-details-for-payment/payment-terminal';
      }
    }
    case 'Paid': {
      return '/app/item-details-for-payment/complete-order';
    }
    default: {
      return '/app/attract';
    }
  }
}
export function roundNumber(value: number) {
  return (Math.round(value * 100) / 100).toFixed(2);
}
export const calculateSubTotal = (itemsInBag) => {
  return itemsInBag.reduce(function (accumulator, item) {
    return accumulator + (item.product.price * item.product.quantity || 0);
  }, 0);
};
export const calculateTotal = (subtotal, taxAmount, discounts, fee1, fee2, fee3) => {
  return (subtotal - discounts + (taxAmount || 0) + (fee1 || 0) + (fee2 || 0) + (fee3 || 0));
};
export const resetStation = async (socketData) => {
  const permanentId = socketData?.permanentId;
  const isValidator = socketData?.stationType === StationType.Validator;
  const params = {
    'isValidatorApp': isValidator ? true : false,
    'voidedFrom': appType
  };
  const { status } = await api(`Stations/permanentId/${permanentId}/reset`, 'post', params);
  return status;
};
export const getUnknownItems = (itemsInBag) => {
  if (itemsInBag)
    return !!itemsInBag?.find((item) => (item.product.isUnknown && item.product.itemStatus !== ItemStatus.Voided));
};

export const handleUpdateStatus = async (stationId, stationStatus) => {
  const { status } = await api(`stations/${stationId}/status/${stationStatus}`, 'patch');
  return status;
};

export const confirmationCompleteTime = {
  min: 5,
  max: 600,
  default: 10
};

export const logToCloud = ({ message = '', data = {}, logLevel = LogLevel.Trace }: LogToCloudType): void => {
  try {
    const requestPayload = {
      logLevel,
      message,
      source: appType,
      data
    };
    api('logging', 'post', requestPayload);
  } catch (e) {
    console.error(e);
  }
};

export const playSound = (url) => {
  const audio = new Audio(url);
  audio.play().then().catch((e) => { console.log('e', e); });
};

export const defaultOptionsForLongPress = { isPreventDefault: true, delay: 1000 };

export const getProductItems = (items) => {
  if (items) {
    const getItems = items.reduce((result, item) => {
      if (item.productName) {
        result[item.productName] = [...result[item.productName] || [], { product: { price: item.productPrice, name: item.productName, productSku: item.productSku, isUnknown: false } }];
      } else {
        //Add a fake item for this unknown product
        const unknownItem = Object.assign({}, item);
        unknownItem.product = {
          price: item?.productPrice || 0,
          name: 'Unknown',
          productSku: item?.productSku || 'Unknown',
          isUnknown: true,
        };
        result['Unknown'] = [...result[unknownItem.product.name] || [], unknownItem];
      }
      return result;
    }, {});
    const finalResult = [];
    Object.keys(getItems).forEach((item) => {
      if (getItems[item] && getItems[item][0] && getItems[item][0].product) {
        finalResult.push({
          product: {
            ...getItems[item][0].product,
            quantity: getItems[item].length,
          }
        });
      }
    });
    return finalResult;
  }
};

export const getReceiptData = (items) => {
  if (items && items.stationGroup && items.currentOrder) {
    const currentOrder = items?.currentOrder;
    const address = items?.stationGroup?.store?.address;
    const phone = items?.stationGroup?.store?.phone;
    const storeName = items?.stationGroup?.store?.name;
    return { ...items, ...currentOrder, phone, storeName, ...address, items: groupItems(currentOrder.items), date: currentOrder.modifiedOn };
  } else {
    return { ...items, ...items?.storeAddress, date: items?.completedAt, items: getProductItems(items?.items) };

  }
};

export const calculateReceiptSubTotal = (itemsInBag) => {
  return itemsInBag.reduce(function (accumulator, item) {
    return accumulator + (parseFloat(item.productPrice) || 0 * parseInt(item.quantity) || 0);
  }, 0);
};

export const getReceiptAmount = (item) => {
  const isDemo = item?.pos === Pos.Demo;
  const subtotal: number = calculateReceiptSubTotal(item?.items);
  const taxForDemo = (subtotal / 100) * taxAmountForDemoMode;
  const taxAmount = isDemo ? taxForDemo : item?.taxAmount || 0;
  const discounts = item?.discountTotalAmount || 0;
  const fee1 = item?.fee1 || 0;
  const fee2 = item?.fee2 || 0;
  const fee3 = item?.fee3 || 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const total: number = calculateTotal(subtotal, taxAmount, discounts, fee1, fee2, fee3);
  return total;
};

export const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;
